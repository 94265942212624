import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from "./auth.module";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fatherName:'',
    id:'',
    motherName:'',
    mothers:[],
    LastName:'',
    Numberfamily:0,
    Numberchild:0,
    // errors:[]
  },
  getters: {},
  mutations: {
    
      // SET_ERROR(state, error) {
      //   state.errors = error;
      // },
    getFName (state, payload) {
      state.fatherName= payload
    },
    getId (state, payload) {
      state.id= payload
    },
    updateMotherr(state){
      state.mothers.push(state.motherName);
    },
    getMName (state, payload) {
      // Vue.set(state.motherName, 'title', payload);
      // state.motherName.push(mom)
      state.motherName= payload
      //console.log(payload)
    
    },
    getLName (state, payload) {
      state.LastName= payload
    },
    getfamily (state, payload) {
      state.Numberfamily= payload
    },
    getchild (state, payload) {
      state.Numberchild= payload
    }
    
  },
  actions: {
    
  },
  modules: {
    app,
    auth,
    appConfig,
    verticalMenu,
 
  },
  strict: process.env.DEV,
})
