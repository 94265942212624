export default [
  {
    path: '/dashboard/statistics',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboard/statistics/statistics-dashboard.vue'),
    meta:{
      resource: 'statistics',
      action:'read'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta:{
      action:'read',
      resource:'dashboard'
          }
  
  },

]
