import ability from './ability'

export const canNavigate = to => to.matched.some(route =>
// //console.log("Roue",route),

  ability.can(route.meta.action || 'read', route.meta.resource) || route.name == 'dashboard-ecommerce' || route.name == 'verfication-code' || route.name == 'add-my-problem'
     || route.name == 'user-profile' || route.name == 'leaves' || route.name == 'leaves-approve')
// //console.log("sss",to.path)

export const _ = undefined
