
import axiosIns from "@/libs/axios.js";
import Vue from "vue";
import JwtService from "@/auth/jwt/useJwt";
// import router from '@/router'
import axios from 'axios'
export const LOGIN = "login";
export const LOGOUT = "logout";
export const  VALIDATE_CODE="VALIDATE_CODE"
export const SET_ERROR_CODE = "setErrorCode";

export const SET_ERROR = "setError";
// axios.defaults.baseURL = 'http://baraem.prokoders-staging-2.xyz/public'
const state = {
  errors: [],
  user: {},
  userid: "",
  token:  '',
  isAuthenticated: ""


};

const getters = {


};
const actions = {


   [LOGIN]({ commit }, credentials) {


  return new Promise((resolve, reject) => {
      axiosIns.post("/api/v1/user/login", credentials)
        .then(({ data }) => {
            // //console.log("Res",res)

            //   localStorage.setItem('email', res.data.data.email)

            if(data.data.user.main_service_id){
                let roleService=data.data.user.main_service_id
                localStorage.setItem('roleService',roleService)
              }
              //console.log("userName",data.data.token)
              const token = data.data.token
              axios.defaults.headers.common['Authorization'] = "Bearer " +token;

              localStorage.setItem('permissions', JSON.stringify(data.data.user.permissions))
              // router.reload()
              // const userId = res.data.user.id
              const userName = data.data.user.first_name
              //console.log("userName",userName)
              // const role = res.data.role

              localStorage.setItem('userName', userName)
              // localStorage.setItem('userId', userId)
              localStorage.setItem('token', token)
              // localStorage.setItem('role', role.name)
              // //console.log("jjjs")

              commit('auth_success', token,userName)

            // context.dispatch(GENERATE_CODE);

          // //console.log("jaajj")
          
          resolve(data)

        })
        .catch((error) => {
         if(error.response){
          commit(
            SET_ERROR,
            "فشلت العملية, تأكد من اسم المستخدم أو كلمة السر")
         }



         else{
          commit(
            SET_ERROR,
            "فشلت العملية")
         }
         reject(error)



        });
    });
  },
  [VALIDATE_CODE]({ commit }, credentials){

    return new Promise((resolve, reject) => {
        let url=`/api/v1/user/otp_validate?email=${credentials.email}&code=${credentials.code}`
        axiosIns.post(url)
        .then(({ data }) => {
            //console.log(data)

            if(data.data.user.main_service){
                let roleService=data.data.user.main_service.id
                localStorage.setItem('roleService',roleService)
              }
              //console.log("userName",data.data.token)
              const token = data.data.token
              axios.defaults.headers.common['Authorization'] = "Bearer " +token;

              localStorage.setItem('permissions', JSON.stringify(data.data.user.permissions))
              // router.reload()
              // const userId = res.data.user.id
              const userName = data.data.user.first_name
              //console.log("userName",userName)
              // const role = res.data.role

              localStorage.setItem('userName', userName)
              // localStorage.setItem('userId', userId)
              localStorage.setItem('token', token)
              // localStorage.setItem('role', role.name)
              // //console.log("jjjs")

              commit('auth_success', token,userName)
          resolve(data);
        })
        .catch((error) => {
            //console.log("er",error)
            if(error){
             commit(
               SET_ERROR,
               "الكود غير صحيح")
            }



            else{
             commit(
               SET_ERROR,
               "فشلت العملية")
            }
            reject(error)



           });
    });
  },



};
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ERROR_CODE](state, error) {
    state.errors = error;
  },
  setToken(state, t) {
    state.token = t;
  },
  auth_success(state, token, user) {
    state.status = 'success'
    state.token = token
    state.user = user
  },

};

export default {
  state,
  actions,
  mutations,
  getters
};
